import { STORAGE_INDEXES } from "../../app/constants";

// function which set the inital value to local storage and return obj//
export function defaultInitialState(LOCAL_STORAGE_KEY) {
  let DEFAULT_INITIAL_STATES = {};
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.IS_AUTHENTICATED] = false;
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.LOADER] = false;
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.EMAIL] = null;
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.NAME] = null;
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.ERROR_MESSAGE] = { auth: null };
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.TOKEN] = null;
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.USER_ID] = 0;
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.USER_ROLE] = null;
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.PROFILE_PICTURE] = null;

  let APP_SETTINGS = {};
  APP_SETTINGS[STORAGE_INDEXES.ROLE_LIST] = {};
  DEFAULT_INITIAL_STATES[STORAGE_INDEXES.APP_SETTINGS] = APP_SETTINGS;
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify(DEFAULT_INITIAL_STATES)
  );
  return DEFAULT_INITIAL_STATES;
}

// function which set value to local storage on login and return obj//
export function authUserData({ user, authorization }) {
  let AUTH_USER_DATA = {};
  //  const  profileImage = data[STORAGE_INDEXES.PROFILE_PICTURE];
  console.log(STORAGE_INDEXES, "STORAGE_INDEXES");
  AUTH_USER_DATA[STORAGE_INDEXES.IS_AUTHENTICATED] = true;
  AUTH_USER_DATA[STORAGE_INDEXES.LOADER] = false;
  AUTH_USER_DATA[STORAGE_INDEXES.EMAIL] = user[STORAGE_INDEXES.EMAIL];
  AUTH_USER_DATA[STORAGE_INDEXES.NAME] = user[STORAGE_INDEXES.NAME];
  AUTH_USER_DATA[STORAGE_INDEXES.ERROR_MESSAGE] = { auth: null };
  AUTH_USER_DATA[STORAGE_INDEXES.TOKEN] = authorization[STORAGE_INDEXES.TOKEN];
  AUTH_USER_DATA[STORAGE_INDEXES.USER_ID] = user[STORAGE_INDEXES.USER_ID];
  AUTH_USER_DATA[STORAGE_INDEXES.USER_ROLE] = user[STORAGE_INDEXES.USER_ROLE];
  // AUTH_USER_DATA[STORAGE_INDEXES.PROFILE_PICTURE] =
  //   profileImage !== undefined ? profileImage : null;
  let APP_SETTINGS = {};
  APP_SETTINGS[STORAGE_INDEXES.ROLE_LIST] = {};
  AUTH_USER_DATA[STORAGE_INDEXES.APP_SETTINGS] = APP_SETTINGS;
  return AUTH_USER_DATA;
}

// function set values to local storage when login failed and return obj//
export function loginFailure(errorMessage) {
  console.log(errorMessage);
  let AUTH_USER_DATA = {};
  AUTH_USER_DATA[STORAGE_INDEXES.IS_AUTHENTICATED] = false;
  AUTH_USER_DATA[STORAGE_INDEXES.LOADER] = false;
  AUTH_USER_DATA[STORAGE_INDEXES.EMAIL] = null;
  AUTH_USER_DATA[STORAGE_INDEXES.NAME] = null;
  AUTH_USER_DATA[STORAGE_INDEXES.ERROR_MESSAGE] = { auth: errorMessage };
  AUTH_USER_DATA[STORAGE_INDEXES.TOKEN] = null;
  AUTH_USER_DATA[STORAGE_INDEXES.USER_ID] = 0;
  AUTH_USER_DATA[STORAGE_INDEXES.USER_ROLE] = null;
  return AUTH_USER_DATA;
}
