import axios from "axios";
import { getToken } from "../Auth";

//--get request--//
export function getRequest(URL) {
  let payload = {},
    headers = {};
  return apiRequest(URL, payload, "get", headers);
}

//--post reuest --//
export function postRequest(URL, payload, headers = {}) {
  return apiRequest(URL, payload, "post", headers);
}

// -- put request --//
export function putRequest(URL, payload, headers = {}) {
  return apiRequest(URL, payload, "put", headers);
}

// -- delete request --//
export function deleteRequest(URL, payload, headers = {}) {
  return apiRequest(URL, payload, "delete", headers);
}

export function posttRequest(URL, payload) {}

// request api function //
export async function apiRequest(
  endPoint,
  data,
  method,
  headers,
  requestOptions = {}
) {
  return new Promise(async (resolve, reject) => {
    const token = getToken();
    const headers = {
      Accept: "application/json",
    };

    if (data instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    } else {
      headers["Content-Type"] = "application/json";
    }

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios({
      method: method,
      url: endPoint,
      headers: headers,
      data: data,
    })
      .then((result) => {
        return resolve(result);
      })
      .catch((error) => {
        return reject(error);
      });
    return false;
  });
}
