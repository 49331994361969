export const RESPONSE_MESSAGES = {
  en: {
    SUCCESS_MESSAGE: `Thank you contacting us!`,
    ERROR_MESSAGE: `Getting error while sending your request!`,
    UNKNOWN_ERROR_MESSAGE: `Getting error while sending your request. Please try again.`,
    TYPE_ERROR: `error`,
    TYPE_SUCCESS: `success`,
    NETWORK_ERROR: `Unable to connect with backend services.`,
    RESET_PASS_SUCCESS: `Password has been updated.`,
    RESET_PASS_FAILED: `Unable to reset password. Please try again.`,
    PROFILE_UPLOAD_FAILED: `Unable to update profile picture. Please try again.`,
    PROFILE_UPLOAD_SUCCESS: `Profile picture has been updated.`,
    FETCHING_ADMIN_DETAIL: `Unable to fetch profile details`,
    FETCHING_USERS_LIST: `Getting error while fetching users. Please try again.`,
    FETCHING_SCREENSHOTS_LIST: `Getting error while fetching screenshots. Please try again`,
    FECTCHING_SCREENSHOT_DETAIL: `Unable to fetch screenshot details`,
    FETCHING_COURSE_LIST: `Getting error while fetching courses. Please try again.`,
    FETCHING_CATEGORIES_LIST: `Getting error while fetching categories. Please try again.`,
    FETCHING_NEWS_LIST: `Getting error while fetching news. Please try again.`,
    FETCHING_FLASHCARD_LIST: `Getting error while fetching flashcard . Please try again.`,
    FETCHING_QUESTION_LIST: `Getting error while fetching questions . Please try again.`,
    FETCHING_PLAN_LIST: `Getting error while fetching plan . Please try again.`,
    FETCHING_BOOKS_CATEGORY_LIST: `Getting error while fetching books category plan . Please try again.`,
    NOT_FOUND_401: `Access Token expired, please login again.`,
    NOT_FOUND_404: `404: Service not found!.`,
    NOT_FOUND_422: `422: Invalid request, please check all fields!.`,
    FETCHING_SUBJECT_LIST: `Getting error while fetching subjects. Please try again.`,
    FETCHING_LESSION_LIST: `Getting error while fetching lessions. Please try again.`,
    FETCHING_BOOK_LIST: `Getting error while fetching books. Please try again.`,
    FETCHING_CHAPTER_LIST: `Getting error while fetching chapters. Please try again.`,
    FETCHING_TOPIC_LIST: `Getting error while fetching lession's topics. Please try again.`,
    FETCHING_TEST_LIST: `Getting error while fetching tests . Please try again.`,
    FETCHING_FLASH_DECK_LIST: `Getting error while fetching flashcard deck's . Please try again.`,
    ECONNABORTED: `Getting too long to process your request. Pease try again.`,
    ERR_BAD_REQUEST: `Getting bad request error while process your request. Pease try again.`,
    INTERNAL_SERVER_ERROR: `Internal server error!`,
    USER_DETAILS: `Unable to fetch user details. please try again.`,
    TOPIC_DETAIL: `Unable to fetch topic details. please try again.`,
    CATEGORY_DETAIL: `Unable to fetch category details. please try again.`,
    NEWS_DETAIL: `Unable to fetch news details. please try again.`,
    COURSE_DEATIL: `Unable to fetch course details. please try again.`,
    SUBJECT_DEATIL: `Unable to fetch subject details. please try again.`,
    LESSION_DEATIL: `Unable to fetch lession details. please try again.`,
    FLASHCARD_DETAIL: `Unable to fetch flashcard details. please try again.`,
    QUESTION_DETAIL: `Unable to fetch question details. please try again.`,
    BOOK_DETAIL: `Unable to fetch book details,please try again.`,
    CHAPTER: `Unable to fetch chapter details,please try again.`,
    TEST_DETAIL: `Unable to fetch test details,please try again.`,
    ASSESSMENT_DETAIL: `Unable to fetch assessment details,please try again.`,
    FETCHING_ASSESSMENT_LIST: `Getting error while fetching assessment . Please try again.`,
    FETCHING_ASSESSMENT_QUESTION_LIST: `Getting error while fetching  questions . Please try again.`,
    FETCHING_APP_CONTENT: `Getting error while fetching app content.`,
    FETCHING_ASSESSMENT_PERFORMANCE: `Unable to fetch assessment performance detail, please try again.`,
    FETCHING_USER_PERFORMANCE: `Unable to fetch performance detail, please try again.`,
    FETCHING_PERFORMANCE_LIST: `Getting error while fetching performance, please try again.`,
    FETCHING_SUBSCRIPTION_DETAIL: `Unable to fetch subscription detail, please try again.`,
    FETCHING_SUBSCRIBED_USERS: `Getting error while fetching subscribed users, please try again.`,
    FETCHING_PLAN_DETAIL: `Unable to fetch plan details, please try again.`,
    FETCHING_PAYMENT_LIST: `Getting error while fetching payment list, please try agian.`,
    FETCHING_PAYMENT_DETAIL: `Unable to find payment detail, please try again.`,
    FETCHING_USER_ENQUIRY: `Unable to fetch user inquiry, please try again.`,
    FETCHING_ENQUIRY_LIST: `Getting error while fetchng enquiry list, please try again.`,
    FETCHING_USER_FEEDBACK: `Getting error while fetching feedback, please try again.`,
    FETCHING_FEEDBACK_DETAIL: `Unable to fetch feedback details, please try again.`,
    FETCHING_QUESTION_FEEDBACKS: `Getting error while fetching question feedbacks, please try again.`,
    FETCHING_QUESTION_FEED_DETAILS: `Unable t0 fetch feedback details, please try again.`,
    FETCHINF_TEST_MODE: `Getting error while fetching test mode, please try again.`,
  },
};

export const SELECT_BOX_TEXT = {
  COURSE: `Search and select course`,
  SUBJECT: `Search and select subject`,
  LESSON: `Search and select lesson`,
  TOPIC: `Search and select topic`,
};
