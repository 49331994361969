// routes
import AppRoutes from "./routes";
import { useSelector } from "react-redux";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import "./App.css";
// ----------------------------------------------------------------------

export default function App() {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <ThemeProvider>
      <ScrollToTop />
      {/* <StyledChart /> */}
      <AppRoutes isAuthenticated={isAuthenticated} />
    </ThemeProvider>
  );
}
