import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';
// import LinearProgress from '@mui/material/LinearProgress';
function ScreenLoader() {
    return <Grid className='screen-loader' item xs={4} style={{textAlign: "center"}}>
        <CircularProgress disableShrink />
    </Grid>
    // return <Grid item xs={4} style={{textAlign: "center"}}>
    //     <LinearProgress />
    // </Grid>
}
export default ScreenLoader;