import { lazy } from "react";

import { ROUTE_SLUGS, AUTH_ROUTE_SLUGS } from "./../app/constants";

// routes admin can access without login //

export const ProtectedRoutes = [
  {
    slug: ROUTE_SLUGS.ROOT,
    component: lazy(() => import("../pages/LoginPage")),
    exact: true,
  },
  {
    slug: ROUTE_SLUGS.LOGIN,
    component: lazy(() => import("../pages/LoginPage")),
    exact: true,
  },

  {
    slug: ROUTE_SLUGS.FORGOT_PASS,
    component: lazy(() => import("../pages/forgot-password")),
    exact: true,
  },
  {
    slug: ROUTE_SLUGS.RESET_PASS,
    parameter: "/:token",
    component: lazy(() => import("../pages/reset-password")),
    exact: true,
  },
];

export const PublicRoutes = [
  // {
  //   slug: AUTH_ROUTE_SLUGS.ROOT,
  //   component: lazy(() => import("../layouts/simple")),
  //   children: [
  //     {
  //       path: AUTH_ROUTE_SLUGS.APP_POLICY,
  //       component: lazy(() => import("../pages/app-policy")),
  //     },
  //   ],
  // },
];

//routes admin can access when login//
export const AuthorizedRoutes = [
  {
    slug: AUTH_ROUTE_SLUGS.ROOT,
    component: lazy(() => import("../layouts/dashboard")),
    exact: true,
  },

  {
    slug: AUTH_ROUTE_SLUGS.DASHBOARD,

    component: lazy(() => import("../layouts/dashboard")),

    children: [
      {
        path: AUTH_ROUTE_SLUGS.APP,
        component: lazy(() => import("../pages/dashboard")),
      },

      {
        path: AUTH_ROUTE_SLUGS.USERS.ROOT,
        component: lazy(() => import("../pages/users")),
      },
      {
        path: AUTH_ROUTE_SLUGS.USERS.ROOT,
        parameter: "/:id",
        component: lazy(() => import("../pages/users/profile")),
      },
      {
        path: AUTH_ROUTE_SLUGS.USERS.CREATE_NEW,
        component: lazy(() => import("../pages/users/create-new-user")),
      },
      {
        path: AUTH_ROUTE_SLUGS.WEBSITE.ROOT,
        component: lazy(() => import("../pages/website")),
      },
      {
        path: AUTH_ROUTE_SLUGS.EVENTS.ROOT,
        component: lazy(() => import("../pages/events/EventPage")),
      },
      {
        path: AUTH_ROUTE_SLUGS.EVENTS.ROOT,
        parameter: "/:slug",
        component: lazy(() => import("../pages/events/view-event")),
      },
      {
        path: AUTH_ROUTE_SLUGS.EVENTS.CREATE_NEW,
        component: lazy(() => import("../pages/events/create-event")),
      },
      {
        path: AUTH_ROUTE_SLUGS.CAMPAIGNS.ROOT,
        component: lazy(() => import("../pages/campaigns/CampaignPage")),
      },
      {
        path: AUTH_ROUTE_SLUGS.CAMPAIGNS.ROOT,
        parameter: "/:slug",
        component: lazy(() => import("../pages/campaigns/view-campaign")),
      },
      {
        path: AUTH_ROUTE_SLUGS.CAMPAIGNS.CREATE_NEW,
        component: lazy(() => import("../pages/campaigns/create-campaign")),
      },
      {
        path: AUTH_ROUTE_SLUGS.ARTICLES.ROOT,
        component: lazy(() => import("../pages/articles/ArticlePage")),
      },
      {
        path: AUTH_ROUTE_SLUGS.ARTICLES.ROOT,
        parameter: "/:slug",
        component: lazy(() => import("../pages/articles/view-article")),
      },
      {
        path: AUTH_ROUTE_SLUGS.ARTICLES.CREATE_NEW,
        component: lazy(() => import("../pages/articles/create-article")),
      },
      {
        path: AUTH_ROUTE_SLUGS.PROFILE,
        component: lazy(() => import("../pages/admin-profile")),
      },
      {
        path: AUTH_ROUTE_SLUGS.ADMIN.CHANGE_PASSWORD,
        component: lazy(() => import("../pages/admin-profile/change-password")),
      },
      {
        path: AUTH_ROUTE_SLUGS.GAGGLES.ROOT,
        component: lazy(() => import("../pages/gaggles/GagglesPage")),
      },
      {
        path: AUTH_ROUTE_SLUGS.GAGGLES.ROOT,
        parameter: "/:slug",
        component: lazy(() => import("../pages/gaggles/view-gaggle")),
      },
      {
        path: AUTH_ROUTE_SLUGS.GAGGLES.CREATE_NEW,
        component: lazy(() => import("../pages/gaggles/create-gaggle")),
      },
      {
        path: AUTH_ROUTE_SLUGS.WEB_MIRROR.ROOT,
        component: lazy(() => import("../pages/web-mirror/WebMirrorPage")),
      },
    ],
  },
];
