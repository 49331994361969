import { STORAGE_INDEXES } from "../../app/constants";
import { defaultInitialState } from "../helpers";
export function setOnLocalStorage(index, data) {
  data = JSON.stringify(data);
  return localStorage.setItem(index, data);
}

export function removeFromLocalStorage(key) {
  return localStorage.removeItem(key);
}

export function getFromLocalStorage(index) {
  return JSON.parse(localStorage.getItem(index));
}

export function setInitialState() {
  const _initialStates = JSON.parse(
    localStorage.getItem(STORAGE_INDEXES.APP_STORAGE)
  );
  return _initialStates === null
    ? defaultInitialState(STORAGE_INDEXES.APP_STORAGE)
    : _initialStates;
}

export function getInitialState() {
  return JSON.parse(localStorage.getItem(STORAGE_INDEXES.APP_STORAGE));
}
