import { combineReducers } from 'redux';
import authenticateReducer from './authenticate/reducer'
import RequestReducer from './reducers/requestReducer';
import ConsoleReducer from './reducers/consoleReducer';

const rootReducer = combineReducers({
  auth: authenticateReducer,
  request: RequestReducer,
  console: ConsoleReducer,
});

export default rootReducer;