import Logo from "../../assets/images/logo.png";
export const APP_NAME = "Bias system";
export const REDIRECT_UNAUTH_ROUTE = "/login";
export const REDIRECT_AUTH_ROUTE = "/dashboard/app";
export const REDIRECT_DEFAULT_ROUTE = "/dashboard/app";

export const AXIOS_ERROR_CODE = {
  ERR_NETWORK: "ERR_NETWORK",
  ECONNABORTED: "ECONNABORTED",
  ERR_BAD_REQUEST: "ERR_BAD_REQUEST",
  ERR_CANCELED: "ERR_CANCELED",
};
export const IMAGES = {
  LOGO: "/assets/images/logo.svg",
};
export const RESPONSE_CODE = {
  200: 200,
  400: 400,
  401: 401,
  422: 422,
  404: 404,
  500: 500,
};

export const STORAGE_INDEXES = {
  IS_AUTHENTICATED: "isAuthenticated",
  TOKEN: "token",
  LOADER: "loader",
  EMAIL: "email",
  NAME: "name",
  AUTH: "auth",
  ERROR_MESSAGE: "errorMessage",
  USER_ID: "id",
  USER_ROLE: "role_name",
  PASSWORD: "password",
  APP_STORAGE: "mz_app_storage",
  APP_SETTINGS_STORAGE: "tg_app_settings",
  USER_DETAILS: "userDetails",
  LOCALE: "locale",
  PROFILE_PICTURE: "profile_picture",
  PAGE_NO: "page",
  NUMBER_OF_ROWS: "limit",
  APP_SETTINGS: "app_settings",
  ROLE_LIST: "role_list",
  ROLE_ID: "role_id",
  CREATED_BY_ADMIN: "created_by_admin",
  SEARCH_TEXT: "search",
};

export const USER_ROLES = {
  ADMIN: "Admin",
  SUB_ADMIN: "Sub_Admin",
  USER: "User",
};

export const PLAN_DURATION = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

// export const GENDER = ["Male", "Female"];

export const GENDER_ENUM_SECOND = [
  { key: "M", value: "Male" },
  { key: "F", value: "Female" },
  { key: "NB", value: "Non-binary" },
  { key: "RTNS", value: "Reffer not to say" },
];

// array of question mode //
export const questionTestMode = [
  {
    id: 1,
    label: "All",
  },
  {
    id: 2,
    label: "Assessment",
  },
  {
    id: 3,
    label: "Quiz",
  },
];

export const colorPlate = [
  "#7EB2DD",
  "#A2C5AC",
  "#FFE900",
  "#FF6978",
  "#0040FF",
];

export const QUIZ_STATUS = [
  {
    status: 0,
    value: "Not started",
  },
  {
    status: 1,
    value: "Completed",
  },
  {
    status: 2,
    value: "Paused",
  },
  {
    status: 3,
    value: "Suspended",
  },
];

export const CHART_QUESTION_OPTIONS = [
  { id: 1, name: "Indicated" },
  { id: 2, name: "Not Indicated " },
];

export const AGE_GROUP = [
  { key: "Below 18", value: "BELOW_18" },
  { key: "18 TO 24", value: "18_TO_24" },
  { key: "25 TO 30", value: "25_TO_30" },
  { key: "31 TO 34", value: "31_TO_34" },
  { key: "35 TO 40", value: "35_TO_40" },
  { key: "41 TO 45", value: "41_TO_45" },
  { key: "46 TO 50", value: "46_TO_50" },
  { key: "ABOVE 50", value: "ABOVE_50" },
];

export const DASHBOARD_SELECTOR = [
  {
    key: "daily",
    value: "Daily",
  },
  {
    key: "weekly",
    value: "Weekly",
  },
  {
    key: "monthly",
    value: "Monthly",
  },
  {
    key: "yearly",
    value: "Yearly",
  },
  {
    key: "all_time",
    value: "All time",
  },
];

export const PLAN_CURRENCY = [
  { key: "USD", value: "USD" },
  { key: "INR", value: "INR" },
];

export const GOAL_WITH_US = [
  { key: "Pass licensing exam", value: "PASS_LICENSING_EXAM" },
  { key: "Pass school exam", value: "PASS_SCHOOL_EXAM" },
  { key: "Refresh knowledge", value: "REFRESH_KNOWLEDGE" },
  { key: "Not sure", value: "NOT_SURE" },
];

export const STUDY_TIME = [
  { key: "Less than 5 minutes/days", value: "LESS_THAN_5_MINUTES/DAY" },
  {
    key: "Between 15 TO 30 minutes/days",
    value: "BETWEEN_15_TO_30_MINUTES/DAY",
  },
  {
    key: "Between 30 to 60 minutes/day",
    value: "BETWEEN_30_TO_60_MINUTES/DAY",
  },

  {
    key: "More than 60 minutes/day",
    value: "MORE_THAN_60_MINUTES/DAY",
  },
  { key: "Not decided", value: "NOT_DECIDED" },
];

export const COURSE_INCLUDES = [
  {
    type: "type_draft",
    name: "digital flashcard",
  },
  {
    type: "type_test",
    name: "Assesment test",
  },
  {
    type: "type_video",
    name: "Review video",
  },
  {
    type: "type_tracking",
    name: "digital flashcard",
  },
];

export const DEFAULT_VALUE = {
  LOCALE: "en",
  APP_LOGO: Logo,
  LOGO: Logo,
  NA: "NA",
  COUNTRY_ISO: "us",
  COUNTRY_DIAL_CODE: "+1",
  FLAG_CDN_W20: "https://flagcdn.com/w20/",
  FLAG_CDN_W40: "https://flagcdn.com/w40/",
};

export const USER_BEHAVIOUR_TYPE = {
  IS_ACTIVE: {
    0: { className: "warning", value: "inactive" },
    1: { className: "success", value: "active" },
    2: { className: "error", value: "suspended" },
    3: { className: "error", value: "removed" },
    4: { className: "error", value: "closed" },
  },
  VERIFIED: {
    0: "not verified",
    1: "verified",
  },
  AVAILABILITY: {
    0: { className: "default", value: "not-available" },
    1: { className: "success", value: "available" },
  },
  CLOSED: {
    0: { className: "error", value: "closed" },
  },
};

export const FRONTEND_WEB_FORMS = {
  1: "contact form",
  2: "enquiry form",
  NA: "na",
};

export const USER_BEHAVIOUR_LIST = {
  IS_ACTIVE: "active",
  AVAILABILITY: "availability",
  VERIFIED: "verified",
  CLOSED: "closed",
};

export const DEFAULT_CSS = {
  BG: "#000",
  LOADER_BG_COLOR_OG: "#e2f6fc",
  LOADER_BG_COLOR: "#fff",
  DARK_BG: "#354054",
  PRIMARY_COLOR: "#1f74ca",
  SUCCESS_MSG_COLOR: "#4caf50",
  ERROR_MSG_COLOR: "#FF4842",
  WARNING_COLOR: "#ffab00",
  SUCCESS_COLOR: "#36b37e",
};

export const ROUTE_SLUGS = {
  ROOT: "/",
  LOGIN: "/login",
  FORGOT_PASS: "/forgot-password",
  RESET_PASS: "/reset-password",
  PROFILE: "/dashboard/profile",
  USER_PROFILE: "profile",
  USERS: "/dashboard/users",
  EVENTS: "/dashboard/events",
  CREATE_USER: "/users/create",
  CAMPAIGNS: "/dashboard/campaigns",
  ARTICLES: "/dashboard/articles",
  CHANGE_PASSWORD: "/dashboard/profile/change-password",
  GAGGLES: "/dashboard/gaggles",
};

export const AUTH_ROUTE_SLUGS = {
  ROOT: "/",
  APP: "app",
  APP_POLICY: "app-policy",
  DASHBOARD: "dashboard",
  PROFILE: "profile",
  USERS: {
    ROOT: "users",
    CREATE_NEW: "users/create",
    PROFILE: "users/profile",
  },
  WEBSITE: {
    ROOT: "website",
  },
  EVENTS: {
    ROOT: "events",
    CREATE_NEW: "events/create",
    PROFILE: "events/get",
  },
  CAMPAIGNS: {
    ROOT: "campaigns",
    CREATE_NEW: "campaigns/create",
    VIEW: "campaigns/get",
  },
  ARTICLES: {
    ROOT: "articles",
    CREATE_NEW: "articles/create",
    VIEW: "articles/get",
  },
  ADMIN: {
    ROOT: "dashboard",
    ADMIN_PROFILE: "dashboard/profile",
    CHANGE_PASSWORD: "profile/change-password",
  },
  GAGGLES: {
    ROOT: "gaggles",
    CREATE_NEW: "gaggles/create",
    VIEW: "gaggles/get",
  },
  WEB_MIRROR: {
    ROOT: "web-mirror",
  },
};

export const DEFAULT_APP_TITLE = {
  PROFILE: "Profile",
  USERS: "Users",
  APP: "App",
  WEBSITE: "Website",
  CREATE_USER: "Create new user",
  USER_PROFILE: "User profile",
  HOME: "Home",
  EVENTS: "Events",
  CREATE_EVENTS: "Create Events",
  CAMPAIGNS: "Campaigns",
  CREATE_CAMPAIGNS: "Create Campaigns",
  EDIT_CAMPAIGNS: "Edit Campaigns",
  ARTICLES: "Articles",
  CREATE_ARTICLES: "Create Articles",
  EDIT_ARTICLES: "Edit Articles",
  ADMIN_PROFILE: "Admin Details",
  CHANGE_PASSWORD: "Change Password",
  GAGGLES: "Gaggles",
  CREATE_GAGGLES: "Create Gaggles",
  WEB_MIRROR: "My Bias",
};

export const USER_STATUS = {
  DEFAULT: 0,
  ACTIVE: 1,
  SUSPENDED: 2,
  REMOVED: 3,
  CLOSED: 4,
};

export const CATEGORIES_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const STATUS_FIELD = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const ASSESSMENT_DURATION = 7200;
export const QUESTION_TYPES = ["MCQ"];
// content pages sections

export const HOME_PAGE_SECTION = {
  HOME_PAGE_DESCRIPTION: "Home page sections content.",
  HOME_PAGE_TYPE: "pageContent",
  HOME_PAGE_SECTION_1: "HOME_SECTION_1",
  HOME_PAGE_SECTION_2: "HOME_SECTION_2",
  HOME_PAGE_SECTION_3: "HOME_SECTION_3",
  HOME_PAGE_SECTION_4_TAB1: "HOME_SECTION_4_TAB_1",
  HOME_PAGE_SECTION_4_TAB2: "HOME_SECTION_4_TAB_2",
  HOME_PAGE_SECTION_4_TAB3: "HOME_SECTION_4_TAB_3",
  HOME_PAGE_SECTION_4_TAB4: "HOME_SECTION_4_TAB_4",
  HOME_PAGE_SECTION_5: "HOME_SECTION_5",
  HOME_PAGE_SECTION_6: "HOME_SECTION_6",
  HOME_PAGE_SECTION_7: "HOME_SECTION_7",
  HOME_PAGE_SECTION_8: "HOME_SECTION_8",
  HOME_PAGE_SECTION_9: "HOME_SECTION_9",
  HOME_PAGE_SECTION_10: "HOME_SECTION_10",
  HOME_PAGE_SECTION_11: "HOME_SECTION_11",
  TERMS_CONDITIONS: "TERMS_CONDITIONS",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  SUBSCRIPTIONS_PRIVACY: "SUBSCRIPTIONS_PRIVACY",
};

export const PLAN_PAGE_KEY = "PLAN_FAQS";
export const GENRAL_SETTINGS = {
  HEADER_SETTING_KEY: "HEADER_SETTING",
  WEBSITE_TITLE: "WEBSITE_TITLE",
  HEADER_LOGO: "HEADER_LOGO",
};

export const DAYS = [
  "None",
  "1 DAY ",
  "2 DAYS",
  "3 DAYS",
  "4 DAYS",
  "5 DAYS",
  "6 DAYS",
  "7 DAYS",
  "8 DAYS",
  "9 DAYS",
  "10 DAYS",
  "11 DAYS",
  "12 DAYS",
  "13 DAYS",
  "14 DAYS",
  "15 DAYS",
  "16 DAYS",
  "17 DAYS",
  "18 DAYS",
  "19 DAYS",
  "20 DAYS",
  "21 DAYS",
  "22 DAYS",
  "23 DAYS",
  "24 DAYS",
  "25 DAYS",
  "26 DAYS",
  "27 DAYS",
  "28 DAYS",
  "29 DAYS",
  "30 DAYS",
  "31 DAYS",
];
