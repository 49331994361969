const actions = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  AUTH_MESSAGE: 'AUTH_MESSAGE',
  GET_AUTH_USER: 'GET_AUTH_USER',
  GET_AUTH_USER_SUCCESS: 'GET_AUTH_USER_SUCCESS',
  GET_AUTH_USER_FAILURE: 'GET_AUTH_USER_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  REGISTER: 'REGISTER',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
}

export default actions;

export const login = (email, password, loader, isAuthenticated) => ({
  type: actions.LOGIN,
  index: actions.LOGIN,
  payload: { email, password, loader, isAuthenticated }
});

export const logout = () => ({
  type: actions.LOGOUT,
  index: actions.LOGOUT,
  payload: null,
});

export const APP_ACTIONS = {
  ROLES: 'ROLES',
  GETTING_ROLES: 'GETTING_ROLES',
  GETTING_ROLES_SUCCESS: 'GETTING_ROLES_SUCCESS',
}

export const gettingRoles = (payload) =>({
  type:APP_ACTIONS.GETTING_ROLES,
  index:APP_ACTIONS.GETTING_ROLES,
  payload:payload,
});

export const REQUEST_ACTION = {
  INIT:'INIT',
  PROCESSING:'PROCESSING',
  FAILURE:'FAILURE',
  SUCCESS:'SUCCESS',
  INIT_LOADER:'INIT_LOADER',
}

export const CONSLE_ACTION = {
  INIT:'INIT',
  RE_POSITION:'RE_POSITION',
  LOGS:'LOGS',
}