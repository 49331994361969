export const BASEURL = process.env.REACT_APP_HOST_URL;
export const BASEURL_TWO = process.env.REACT_APP_API;
export const PREFIX = "/admin";
export const PREFIX_TWO = "/api";
export const SAMPLE_API = `${BASEURL}${PREFIX}/sample-api`;

export const AUTH = {
  login: `${BASEURL_TWO}${PREFIX_TWO}/login`,
  LOGIN: `${BASEURL}${PREFIX}/login`,
  FORGOT_PASSWORD: `${BASEURL_TWO}${PREFIX_TWO}/forget-password`,
  RESET_PASSWORD: `${BASEURL_TWO}${PREFIX_TWO}/reset-password`,
  UPDATE_PASSWORD: `${BASEURL}${PREFIX}/change-password`,
  UPDATE_PROFILE_IMAGE: `${BASEURL}${PREFIX}/update-profile-image`,
  REMOVE_PROFILE_IMAGE: `${BASEURL}${PREFIX}/remove-profile-image`,
  UPDATE_PROFILE: `${BASEURL}${PREFIX}/update-profile`,
};

export const ADMIN = {
  GET_PROFILE: `${BASEURL_TWO}${PREFIX_TWO}/my-details`,
  EDIT_PROFILE: `${BASEURL_TWO}${PREFIX_TWO}/admin/update/slug`,
  UPLOAD_IMAGE: `${BASEURL}${PREFIX}/profile-image`,
  DELETE_PROFILE_IMAGE: `${BASEURL}${PREFIX}/delete-profile-image`,
  CHANGE_PASSWORD: `${BASEURL_TWO}${PREFIX_TWO}/change-password`,
  EXPORT_DATA: `${BASEURL}${PREFIX}/user-csv`,
  EXPORT_QUIZ: `${BASEURL}/test/test-csv`,
  UPLOAD_CHAPTER_CONTENT: `${BASEURL}${PREFIX}/ck-image-upload`,
  REMOVE_CHAPTER_CONTENT: `${BASEURL}${PREFIX}/ck-image-delete`,
  EXPORT_USER_PERFORMANCE: `${BASEURL}${PREFIX}/performance-csv `,
};

export const USERS = {
  CREATE: `${BASEURL_TWO}/api/registration`,
  LIST: `${BASEURL_TWO}${PREFIX_TWO}/admin/user/listing`,
  SEARCH_USER: `${BASEURL}${PREFIX}/search-user`,
  AGGREGATES: `${BASEURL_TWO}${PREFIX_TWO}/dashboard`,
  GET_USER: `${BASEURL_TWO}${PREFIX_TWO}/admin/user/view/id`,
  DELETE_USER: `${BASEURL_TWO}${PREFIX_TWO}/admin/user/delete`,
  PROFILE_VERIFY: `${BASEURL}${PREFIX}/verify-guide-profile`,
  UPDATE_STATUS: `${BASEURL}${PREFIX}/update-status`,
  UPDATE_USER_STATUS: `${BASEURL_TWO}${PREFIX_TWO}/user-change-status`,
  UPDATE_USER_DETAILS: `${BASEURL_TWO}${PREFIX_TWO}/admin/update/slug`,
  UPDATE_USER_PROFILE_IMAGE: `${BASEURL}${PREFIX}/profile-image`,
  REMOVE_USER_PROFILE_IMAGE: `${BASEURL}${PREFIX}/delete-profile-image`,
  VALIDATE_START_TAG: `${BASEURL}${PREFIX}/add-star-tag`,
};

export const APP_SETTINGS = {
  ROLE_LIST: `${BASEURL}${PREFIX}/fetch-role`,
};

export const NEWS = {
  LIST: `${BASEURL}/news/all-news`,
  CREATE: `${BASEURL}/news/new-news`,
  VIEW_NEWS: `${BASEURL}/news/get-news`,
  EDIT_NEWS: `${BASEURL}/news/edit-news/`,
  DELETE_NEWS: `${BASEURL}/news/delete-news`,
};

export const EVENTS = {
  LIST: `${BASEURL_TWO}${PREFIX_TWO}/admin/event/list`,
  CREATE: `${BASEURL_TWO}${PREFIX_TWO}/admin/event/store`,
  VIEW: `${BASEURL_TWO}${PREFIX_TWO}/admin/event/view/slug`,
  EDIT: `${BASEURL_TWO}${PREFIX_TWO}/admin/event/update`,
  DELETE: `${BASEURL_TWO}${PREFIX_TWO}/admin/event/delete`,
};

export const CAMPAIGNS = {
  LIST: `${BASEURL_TWO}${PREFIX_TWO}/admin/campaign/list`,
  CREATE: `${BASEURL_TWO}${PREFIX_TWO}/admin/campaign/store`,
  VIEW: `${BASEURL_TWO}${PREFIX_TWO}/admin/campaign/view/slug`,
  EDIT: `${BASEURL_TWO}${PREFIX_TWO}/admin/campaign/update`,
  DELETE: `${BASEURL_TWO}${PREFIX_TWO}/admin/campaign/delete`,
};

export const ARTICLES = {
  LIST: `${BASEURL_TWO}${PREFIX_TWO}/admin/article/list`,
  CREATE: `${BASEURL_TWO}${PREFIX_TWO}/admin/article/store`,
  VIEW: `${BASEURL_TWO}${PREFIX_TWO}/admin/article/view/slug`,
  EDIT: `${BASEURL_TWO}${PREFIX_TWO}/admin/article/update`,
  DELETE: `${BASEURL_TWO}${PREFIX_TWO}/admin/article/delete`,
};
export const INTRO_VIDEO = {
  LIST: `${BASEURL_TWO}${PREFIX_TWO}/admin/intro-video/index`,
  EDIT: `${BASEURL_TWO}${PREFIX_TWO}/admin/intro-video/update`,
};

export const GAGGLES = {
  LIST: `${BASEURL_TWO}${PREFIX_TWO}/admin/gaggle/list`,
  CREATE: `${BASEURL_TWO}${PREFIX_TWO}/admin/gaggle/store`,
  DELETE: `${BASEURL_TWO}${PREFIX_TWO}/admin/gaggle/delete`,
  EDIT: `${BASEURL_TWO}${PREFIX_TWO}/admin/gaggle/update`,
  VIEW: `${BASEURL_TWO}${PREFIX_TWO}/admin/gaggle/view/slug`,
};

export const WEB_MIRROR = {
  LIST: `${BASEURL_TWO}${PREFIX_TWO}/admin/mapping`,
};
